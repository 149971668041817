<template>
  <AuthLayout>
    <div class="auth">
      <h1 class="auth__title auth__title--forced">Вход</h1>
      <Tabs bottom fluid :tabs="[
        { name: 'Войти', to: { name: 'auth-login' } },
        { name: 'Создать аккаунт', to: { name: 'auth-register-entry' } }
      ]" replace />
      <NuxtPage />
    </div>
  </AuthLayout>
</template>

<script setup>
useHead({
  title: 'Вход'
})
</script>

<style scoped lang="scss">

</style>
